<template>
  <div
    class="card card-outlined-icon card-outline-tertiary text-dark h-100 d-flex flex-column justify-content-between py-3 px-4"
  >
    <div class="card-header minh-2">
      <div>
        <slot name="title" />
      </div>
      <ImageContainer :fileName="`icon-${icon}`" contain size="100" />
    </div>
    <div class="card-body minh-md-2">
      <slot />
    </div>
  </div>
</template>

<script>
import ImageContainer from "@/components/ImageContainer.vue";

export default {
  components: {
    ImageContainer,
  },
  props: {
    icon: {
      type: String,
      default: "building",
    },
  },
};
</script>
