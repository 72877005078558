<template>
  <label class="checkbox" :class="{ is_invalid: isInvalid }">
    <span>{{ label }}</span>
    <input type="radio" :name="name" :value="inputValue" v-model="result" />
    <span class="checkmark" />
  </label>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [Boolean, String, Number],
      default: null,
    },
    inputValue: {
      type: [Boolean, String, Number],
      default: null,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
  },
  computed: {
    result: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      },
    },
  },
};
</script>

<style scoped lang="scss"></style>
