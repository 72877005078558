<template>
  <div>
    <PageHeader fileName="sv_header">
      <h3>Studera med CSN</h3>
    </PageHeader>

    <section class="py-6">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 d-flex flex-column">
            <h2>Lev ut din <span class="text-tertiary">dröm</span></h2>
            <h4>Studera spanska på NL College och få CSN</h4>
          </div>
          <div class="col-lg-6 offset-lg-2">
            <h6 class="fw-400 lh-3 mb-4 mt-3">Välkommen till NL College.</h6>
            <p>
              NL College är stolta över att samarbeta med den svenska statliga
              myndigheten CSN, vilket gör det möjligt för svenska studenter att
              få ekonomiskt stöd för språkkurser i Spanien. Oavsett om du vill
              förbättra dina karriärmöjligheter eller fördjupa dig i den lokala
              kulturen, är våra skräddarsydda kurser utformade för att passa
              dina behov.
            </p>
          </div>
        </div>
        <div class="row mt-5 justify-content-center gap-y-4">
          <div class="col-sm-9 col-md-4">
            <ImageContainer
              class="img-container-rounded"
              style="aspect-ratio: 1; --size: auto"
              fileName="sv_01"
            />
          </div>
          <div class="col-sm-9 col-md-4">
            <ImageContainer
              class="img-container-rounded"
              style="aspect-ratio: 1; --size: auto"
              fileName="sv_02"
            />
          </div>
          <div class="col-sm-9 col-md-4">
            <ImageContainer
              class="img-container-rounded"
              style="aspect-ratio: 1; --size: auto"
              fileName="sv_03"
            />
          </div>
        </div>
      </div>
    </section>

    <SectionHeader fileName="sv_04">
      <h1>Vår skola är godkänd av <span class="text-tertiary">CSN</span></h1>
    </SectionHeader>

    <section class="bg-dark py-7">
      <div class="container">
        <h4 class="text-tertiary text-center mb-5">
          Varför välja NL College för dina spanskastudier?
        </h4>

        <div class="row gap-y-4">
          <div class="col-lg-4">
            <div
              class="card card-outline-tertiary text-white h-100 d-flex flex-column justify-content-between py-3 px-4"
            >
              <div class="card-header minh-1">
                <h4>Ekonomiskt stöd genom CSN</h4>
              </div>
              <div class="card-body minh-md-2">
                <p class="mb-0">
                  Svenska studenter kan enkelt ansöka om ekonomiskt stöd genom
                  CSN för att finansiera sin spanskakurs på NL College. Detta
                  inkluderar både bidrag och lån, vilket gör det prisvärt att
                  studera utomlands.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div
              class="card card-outline-tertiary text-white h-100 d-flex flex-column justify-content-between py-3 px-4"
            >
              <div class="card-header minh-1">
                <h4>Förbättra dina karriärmöjligheter</h4>
              </div>
              <div class="card-body minh-md-2">
                <p class="mb-0">
                  Flytande spanska förbättrar avsevärt möjligheterna att få jobb
                  i Spanien. Våra kurser säkerställer att du får de
                  språkkunskaper som behövs för att lyckas på arbetsmarknaden.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div
              class="card card-outline-tertiary text-white h-100 d-flex flex-column justify-content-between py-3 px-4"
            >
              <div class="card-header minh-1">
                <h4>Bli en del av det lokala samhället</h4>
              </div>
              <div class="card-body minh-md-2">
                <p class="mb-0">
                  Att förstå och tala spanska hjälper dig att engagera dig
                  djupare i den lokala kulturen, vilket gör ditt liv i Spanien
                  roligare och enklare.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-center my-5">
          <a
            href="#form"
            class="btn text-center px-5 d-inline-block"
            :class="`btn-tertiary`"
          >
            Jag väljer NL College
          </a>
        </div>
      </div>
    </section>

    <SectionHeader fileName="sv_05">
      <h1>Våra <span class="text-tertiary">kurser</span></h1>
    </SectionHeader>

    <section class="py-7">
      <div class="container">
        <div class="row gap-y-4">
          <div class="col-lg-4 order-2 order-lg-1">
            <div
              class="card card-outline-tertiary text-dark h-100 d-flex flex-column justify-content-between py-3 px-4"
            >
              <div class="card-header minh-1">
                <h5>Intensiv spanskakurs</h5>
              </div>
              <div class="card-body minh-md-2">
                <p class="mb-0">
                  Perfekt för dig som vill lära dig spanska snabbt. Flexibelt
                  schema gör att du kan studera från 3 veckor (minimum för att
                  få CSN) upp till 1 år. Denna kurs räknas som studier på
                  heltid.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 order-4 order-lg-2">
            <div
              class="card card-outline-tertiary text-dark h-100 d-flex flex-column justify-content-between py-3 px-4"
            >
              <div class="card-header minh-1">
                <h5>Morgon- och eftermiddagsskurser</h5>
              </div>
              <div class="card-body minh-md-2">
                <p class="mb-0">
                  Om du föredrar att studera på deltid så har vi kurser som
                  passar ditt schema. För att ansöka om CSN så behöver du
                  studera minst på halvtid (10 timmar per vecka).
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 order-6 order-lg-3">
            <div
              class="card card-outline-tertiary text-dark h-100 d-flex flex-column justify-content-between py-3 px-4"
            >
              <div class="card-header minh-1">
                <h5>Kulturella aktiviteter</h5>
              </div>
              <div class="card-body minh-md-2">
                <p class="mb-0">
                  Lär dig även utanför klassrummet med våra kulturella
                  aktiviteter, evenemang, utflykter, kulinariska upplevelser och
                  språkutbyte.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 order-1 order-lg-4">
            <ImageContainer
              class="img-container-rounded"
              style="aspect-ratio: 1; --size: auto"
              fileName="sv_06"
            />
          </div>
          <div class="col-lg-4 order-3 order-lg-5">
            <ImageContainer
              class="img-container-rounded"
              style="aspect-ratio: 1; --size: auto"
              fileName="sv_07"
            />
          </div>
          <div class="col-lg-4 order-5 order-lg-6">
            <ImageContainer
              class="img-container-rounded"
              style="aspect-ratio: 1; --size: auto"
              fileName="sv_08"
            />
          </div>
        </div>

        <div class="d-flex justify-content-center my-5">
          <a
            href="#form"
            class="btn text-center px-5 d-inline-block"
            :class="`btn-tertiary`"
          >
            Läs mer om våra kurser
          </a>
        </div>
      </div>
    </section>

    <SectionHeader fileName="sv_09">
      <h1>Hur du <span class="text-tertiary">anmäler dig</span></h1>
    </SectionHeader>

    <section class="py-7">
      <div class="container">
        <div class="row gap-y-4">
          <div class="col-lg-6">
            <OutlinedIconCard icon="list2">
              <template #title>
                <h3>Steg 1</h3>
                <h4>Gör ett nivåtest</h4>
              </template>

              <p class="m-0">
                Börja med vårt digitala nivåtest för att bestämma din nuvarande
                nivå i spanska. Detta hjälper oss att placera dig i rätt grupp.
              </p>
            </OutlinedIconCard>
          </div>
          <div class="col-lg-6">
            <OutlinedIconCard icon="hand">
              <template #title>
                <h3>Steg 2</h3>
                <h4>Välj din kurs</h4>
              </template>

              <p class="m-0">
                Välj den kurs som passar ditt schema och dina mål. Vi erbjuder
                kurser på heltid och deltid med olika inriktningar. Du behöver
                studera minst tre veckor på halvtid eller mer för att ha rätt
                att ansöka om ekonomiskt stöd från CSN. Kontakta oss gärna för
                att få hjälp att välja en passande kurs.
              </p>
            </OutlinedIconCard>
          </div>
          <div class="col-lg-6">
            <OutlinedIconCard icon="upload">
              <template #title>
                <h3>Steg 3</h3>
                <h4>Ansök om CSN-stöd</h4>
              </template>

              <p class="m-0">
                Kontakta CSN för att ansöka om ekonomiskt stöd. NL College
                hjälper dig att fylla i antagningsintyget (formulär 5509W) som
                du behöver för att kunna göra ansökan.
              </p>
            </OutlinedIconCard>
          </div>
          <div class="col-lg-6">
            <OutlinedIconCard icon="book2">
              <template #title>
                <h3>Steg 4</h3>
                <h4>Börja plugga!</h4>
              </template>

              <p class="m-0">
                Lär dig spanska medan du njuter av livet i soliga Spanien.
              </p>
            </OutlinedIconCard>
          </div>
        </div>
      </div>
    </section>

    <SectionHeader fileName="sv_010">
      <h1>Viktig information <span class="text-tertiary">om CSN-stöd</span></h1>
    </SectionHeader>

    <section class="py-7 text-dark">
      <div class="container">
        <ConnectedList
          collapse
          :items="accordionItems"
          :defaultActiveIndex="0"
        />
      </div>
    </section>

    <section>
      <div
        class="img-container text-white py-7 contact-section"
        style="background-image: url('/web-v2/img/sv_footer.png')"
      >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-9 text-center">
              <h3 class="mb-5">Bo i ett av de vackraste länderna i världen</h3>
            </div>

            <div class="col-md-9 col-lg-7">
              <form
                id="form"
                action="#form"
                @submit="onSubmit"
                class="d-flex flex-column gap-1"
              >
                <input
                  type="text"
                  placeholder="Namn*"
                  required
                  name="your-name"
                  v-model="form.name"
                />
                <input
                  type="text"
                  placeholder="E-post*"
                  required
                  name="your-email"
                  v-model="form.email"
                />
                <input
                  type="text"
                  placeholder="Telefonnummer*"
                  required
                  name="your-phone"
                  v-model="form.phone"
                />
                <Select
                  v-model="form.course"
                  label="Önskad kurs*"
                  :options="courses"
                  name="your-course"
                />
                <div class="ml-4">
                  <p class="mb-2">Jag vill studera i:</p>

                  <div class="d-flex gap-4">
                    <Radio
                      label="Barcelona"
                      inputValue="Barcelona"
                      name="campus"
                      v-model="form.campus"
                      :class="`checkbox-tertiary`"
                    />
                    <Radio
                      label="Madrid"
                      inputValue="Madrid"
                      name="campus"
                      v-model="form.campus"
                      :class="`checkbox-tertiary`"
                    />
                  </div>
                </div>

                <Checkbox
                  class="ml-4 checkbox-tertiary"
                  label="Jag har läst och accepterat NL College:s villkor och avbokningspolicy."
                  v-model="form.terms"
                  name="terms-accepted"
                  :isInvalid="terms_invalid"
                />

                <button type="submit" class="btn btn-block btn-tertiary mt-4">
                  Jag vill veta mer
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import PageHeader from "@/components/PageHeader.vue";
import ImageContainer from "@/components/ImageContainer.vue";
import SectionHeader from "@/components/SectionHeader.vue";
import OutlinedIconCard from "@/components/OutlinedIconCard.vue";
import ConnectedList from "@/components/ConnectedList.vue";
import Select from "@/components/Forms/Select.vue";
import Checkbox from "@/components/Forms/Checkbox.vue";
import Radio from "@/components/Forms/Radio.vue";

export default {
  components: {
    PageHeader,
    ImageContainer,
    SectionHeader,
    OutlinedIconCard,
    ConnectedList,
    Select,
    Checkbox,
    Radio,
  },
  data: () => ({
    accordionItems: [
      {
        title: "Vad är CSN?",
        body: `<p>CSN är den svenska statliga myndigheten som administrerar ekonomiskt stöd för studenter. Detta inkluderar bidrag och lån för studier både i Sverige och utomlands.</p>`,
      },
      {
        title: "Hur ansöker man om CSN-stöd?",
        body: `<p>Att ansöka om bidrag eller lån är enkelt. Besök CSN:S hemsida eller ring +46 771 276 000 för att få mer information om vad som gäller för just dig. Du fyller i ansökan online. Antagningsintyget 5509W, som NL College kommer att fylla i åt dig, är nödvändigt för att kunna göra ansökan.</p>`,
      },
      {
        title: "Vad täcker CSN?",
        body: `<p>Du kan ansöka om finansiering för att täcka studieavgifter, boende och resan till och från Spanien. Kontakta CSN för mer information om alternativ och belopp.</p>`,
      },
      {
        title: "När ska man ansöka?",
        body: `<p>Skicka in din ansökan minst sex veckor före kursens startdatum. Detta gör att CSN kan behandla din ansökan på ungefär tre veckor, vilket säkerställer att du får ditt ekonomiska stöd innan kursen börjar.</p>`,
      },
      {
        title: "Hur länge kan jag få stöd?",
        body: `<p>CSN:s ekonomiska stöd kan täcka upp till 80 veckors studier och i vissa fall längre. Vår långsiktiga kurs för att nå en B2-nivå tar 36 veckor, och för att uppnå en C2-nivå kan du behöva upp till 80 veckors studier.</p>`,
      },
    ],
    courses: [
      {
        value: "Intensiv spanskakurs",
        label: "Intensiv spanskakurs",
      },
      {
        value: "Morgon-och eftermiddagsskurser",
        label: "Morgon-och eftermiddagsskurser",
      },
    ],
    terms_invalid: false,
    form: {
      name: null,
      email: null,
      phone: null,
      course: null,
      campus: null,
      terms: false,
    },
  }),
  methods: {
    onSubmit(e) {
      e.preventDefault();
      if (!this.isValid()) return;

      axios
        .get("https://api.pumpmylead.com/integrations/informs/v1/webhook", {
          params: {
            "your-name": this.form.full_name,
            "your-email": this.form.email,
            phone: this.form.phone,
            course: this.form.course,
            campus: this.form.campus,
            token: "hk0hyj23020m3gpx119517g46942eztb4",
          },
        })
        .then(() => {
          this.clearForm();
          this.formSuccessMessage();
        });
    },
    isValid() {
      if (!this.form.terms) {
        this.$toast.success(this.$lang("contact_form.check_terms_error", true));
        this.terms_invalid = true;
        return false;
      }

      this.terms_invalid = false;
      return true;
    },
    clearForm() {
      this.tried = false;
      this.terms_invalid = false;
      this.form = {
        full_name: null,
        email: null,
        phone: null,
        country: null,
        nationality: null,
        campus: "Barcelona",
        message: null,
        terms: true,
      };
    },
    formSuccessMessage() {
      this.$toast.success(this.$lang("contact_form.success_msg", true));
    },
  },
};
</script>
