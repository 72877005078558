<template>
  <div
    class="img-container"
    :style="style"
    :class="{
      'responsive-md': withTablet,
      'responsive-sm': withMobile,
      contain: contain,
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    fileName: {
      type: String,
      default: null,
    },
    contain: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 100,
    },
    withTablet: {
      type: Boolean,
      default: false,
    },
    withMobile: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    style() {
      return `
        --min-radius: ${this.size}px;
        --bg-old: url('/web-v2/img/${this.fileName}.png');
        --bg: url('/web-v2/webp/${this.fileName}.webp');
        --bg-md-old: url('/web-v2/img/${this.fileName}-md.png');
        --bg-md: url('/web-v2/webp/${this.fileName}-md.webp');
        --bg-sm-old: url('/web-v2/img/${this.fileName}-sm.png');
        --bg-sm: url('/web-v2/webp/${this.fileName}-sm.webp');
      `;
    },
  },
};
</script>
