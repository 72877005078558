<template>
  <label class="checkbox" :class="{ is_invalid: isInvalid }">
    <span v-html="label" />
    <input
      type="checkbox"
      :checked="checked"
      @change="$emit('change', $event.target.checked)"
      @input="$emit('update:modelValue', $event.target.checked)"
    />
    <span class="checkmark" />
  </label>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    checked: false,
  }),
  mounted() {
    this.checked = this.modelValue;
  },
};
</script>

<style scoped lang="scss"></style>
